<template>
	<div>
		<div class="toolBar mBottom10">
			<el-button type="primary" size='small' plain @click="isShowSelDlg = true">查询</el-button>
			<el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
		</div>

		<el-card class="box-card">
			<el-table :data="logList" size="small" class="maxWidth" stripe :height='tableHeight'>
				<el-table-column type="index" width="50" label='序号' align="left"></el-table-column>
				<el-table-column prop="name" label="用户名" align="left"  width="120"></el-table-column>
				<el-table-column prop="phone" label="手机号" align="left"  width="120"></el-table-column>
				<el-table-column prop="action" label="操作行为" align="left"  width="180"></el-table-column>
				<el-table-column prop="data" label="操作数据" align="left"></el-table-column>
				<el-table-column prop="createTime" label="操作时间" align="left"  width="180"></el-table-column>
			</el-table>
		</el-card>

		<div class="toolBar clearfix" style="margin-top: 10px">
			<pagi-nation :page='page' @sizeChange='pageSizeChange' @currentChange='pageCurrentChange'></pagi-nation>
		</div>

		<!-- 查询 -->
		<el-dialog title="查询" v-dialogDrag :visible.sync="isShowSelDlg" width="40%">
			<el-form :model="logData" size='small' label-width="100px">
				<el-row>
					<el-col :sm="24">
						<el-form-item label="用户名">
							<el-input v-model="logData.name" clearable placeholder="用户名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24">
						<el-form-item label="用户手机号">
							<el-input :maxlength=11 v-model="logData.phone" clearable placeholder="用户手机号"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24">
						<el-form-item label='时间'>
							<el-date-picker style="width: 100%" value-format="yyyy-MM-dd" :clearable="false" @change='dataChange' v-model="dateValue" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
					<el-button @click="isShowSelDlg = false" size='small'>取消</el-button>
					<el-button type="primary" @click="searchLog" size='small'>确定</el-button>
				  </span>
		</el-dialog>
	</div>
</template>

<script>
    class Log{
        constructor(){
            this.beginDate = null;
            this.endDate = null;
            this.name = null;
            this.phone = null;
        }
        reset(){
            this.constructor();
        }
    };

    export default {
        name: 'permissionConfig',
        data() {
            return {
                logList: [],
                tableHeight: document.documentElement.clientHeight - 283,
                page: new this.Page(),

                isShowSelDlg: false,

                //查询
                logData:new Log(),

                dateValue: [],
            }
        },
        computed : {

        },
        methods: {
            handleDetail(row) {
                this.$router.push({name: 'roleDetail', params: {id: row.id}});
            },
            pageSizeChange(val) {
                this.page.currentPage = 1;
                this.page.pageSize = val;
                this.getLogList();
            },
            pageCurrentChange(val) {
                this.page.currentPage = val;
                this.getLogList();
            },
            dataChange(val) {
                if(!val) {
                    this.dateValue = [];
                }
                this.searchForm.beginDate = this.dateValue[0];
                this.searchForm.endDate = this.dateValue[1];
            },
            searchLog(){
                this.page.reset();
                this.getLogList();
                this.isShowSelDlg = false;
            },
            async getLogList() {
                let param = {
                    beginDate:this.logData.beginDate,
                    endDate:this.logData.endDate,
                    phone:this.logData.phone,
                    name:this.logData.name,
                    limit:this.page.pageSize,
                    offset: (this.page.currentPage - 1) * this.page.pageSize
                };
                let res = await this.$http.post('/sys/getSysLog', param);
                if (res.state) {
                    for(let item of res.data.list){
                        item.createTime = this.getDateTime(item.createTime);
                    }
                    this.logList = res.data.list;
                    this.page.total = res.data.totalCount;
                }
            },
            refresh(){
                this.page.reset();
                this.logData.reset();
                let startTime = this.getDate();
                let endTime = this.getDateAddDays(1);
                this.dateValue = [startTime, endTime];
                this.logData.beginDate = this.dateValue[0];
                this.logData.endDate = this.dateValue[1];
                this.getLogList();
            }
        },
        created(){
            let startTime = this.getDate();
            let endTime = this.getDateAddDays(1);
            this.dateValue = [startTime, endTime];
            this.logData.beginDate = this.dateValue[0];
            this.logData.endDate = this.dateValue[1];
            this.getLogList();
        },
        mounted() {
            window.onresize = () => {
                window.fullHeight = document.documentElement.clientHeight;
                this.tableHeight = window.fullHeight - 283;
            }
        }
    }

</script>

<style>
</style>